//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

.section-xs,
.section-sm {
  padding-top: 35px;
  padding-bottom: 35px;
}

.section-md,
.section-lg,
.section-xl {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-xxl {
  padding-top: 75px;
  padding-bottom: 75px;
}

.section-collapse+.section-collapse {
  padding-top: 0;
}

@include media-breakpoint-down(sm) {

  .section-xs,
  .section-sm,
  .section-md,
  .section-lg,
  .section-xl {
    &:first-of-type {
      padding-top: 40px;
    }
  }
}

@include media-breakpoint-up(md) {
  .section-xs {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-md {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-xl {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .section-alternate-1 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@include media-breakpoint-up(lg) {
  .section-md {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section-lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-xl {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: map_get($grid-breakpoints, xl)) and (min-height: $screen-height-desktop) {
  .section-md {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-lg {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  .section-xl {
    padding-top: 190px;
    padding-bottom: 190px;

    &.section-xl_bigger {
      padding-top: 210px;
      padding-bottom: 210px;
    }
  }

  .section-xxl {
    padding-top: 165px;
    padding-bottom: 190px;
  }

  .section-xs,
  .section-sm,
  .section-md,
  .section-lg {
    &:first-of-type {
      padding-top: 140px;
    }
  }
}

// Custom
.section-divided {}

.section-divided__main,
.section-divided__aside {
  >*:first-child {
    padding-top: 0;
  }

  >*:last-child {
    padding-bottom: 0;
  }

  [class*='section']:nth-child(n + 2) {
    border-top: 1px solid $gray-1;
  }
}

.section-divided__aside {

  h5+*,
  .heading-5+* {
    margin-top: 23px;
  }

  *+h5 {
    margin-top: 30px;
  }

  @include media-breakpoint-between(md, md) {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(wrap);
    margin-left: -25px;
    margin-right: -25px;

    >[class*='section'] {
      width: calc(50% - 50px);
      margin-left: 25px;
      margin-right: 25px;

      &:nth-child(2) {
        padding-top: 0;
        border-top: 0;
      }
    }

    >.section-style-1 {
      width: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    >* {
      max-width: 370px;
      margin-left: 50px;
    }
  }

  &.section__aside-left {
    @include media-breakpoint-up(xl) {
      >* {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}

.section-divided__aside-left {
  @include media-breakpoint-up(xl) {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25px;
      content: "";
      display: inline-block;
      border-left: 1px solid $gray-1;
    }
  }
}

.section-divided__main-left {
  @include media-breakpoint-up(xl) {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -25px;
      content: "";
      display: inline-block;
      border-left: 1px solid $gray-1;
    }
  }
}


.section-skew-box-start {
  margin-top: 0px;
  padding: 5% 20px;
  /* Added a percentage value for top/bottom padding to keep the wrapper inside of the parent */

  -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -o-transform: skewY(-5deg);
  transform: skewY(-5deg);
}

.section-skew-box-start>.section-wrapper {
  -webkit-transform: skewY(5deg);
  -moz-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  -o-transform: skewY(5deg);
  transform: skewY(5deg);
}

.section-skew-box {
  margin-top: 50px;
  padding: 5% 20px;
  /* Added a percentage value for top/bottom padding to keep the wrapper inside of the parent */

  -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -o-transform: skewY(-5deg);
  transform: skewY(-5deg);
}

.section-skew-box>.section-wrapper {
  -webkit-transform: skewY(5deg);
  -moz-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  -o-transform: skewY(5deg);
  transform: skewY(5deg);
}

.section-img {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: white;
  background: url("/img/bg-pattern.webp"), #fa225b;
  background: url("/img/bg-pattern.webp"), linear-gradient(to left, #fa225b, #f78c29);
}

.slant {
  position: relative;
  z-index: 1;
}

.slant:after {
  background: inherit;
  bottom: 0;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(-4deg);
  transform-origin: 100%;
  z-index: -1;
  /*background-image: url("/img/unicorn-sil.png"), linear-gradient(to right, #fa225b, #f78c29);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;*/
}

header.masthead .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 50px;
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }

  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }

  header.masthead .header-content h1 {
    font-size: 50px;
  }

  header.masthead .device-container {
    max-width: 325px;
  }
}

//fullwidth page
//

.fullwidth-page {
  @include display-flex;
  @include align-items(center);
  min-height: 100vh;

  .section-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  >* {
    flex-basis: 100%;
  }

  *+.countdown {
    margin-top: 45px;
  }

  *+h5 {
    margin-top: 35px;

    @media (min-width: 768px) {
      margin-top: 55px;
    }
  }

  &_mod-1 {
    min-height: 90vh;
  }
}

.ie-10,
.ie-11 {
  .fullwidth-page {
    white-space: nowrap;

    >* {
      display: inline-block;
      width: 100%;
      white-space: normal;
    }

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      min-height: inherit;
    }
  }
}

.section-infographic {
  margin-bottom: 3rem;

  svg {
    transform: scale(2);
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;

    svg {
      transform: scale(1.5);
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;

    svg {
      transform: scale(1);
    }
  }
}