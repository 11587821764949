//
// Search Results
// --------------------------------------------------

.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 600px;
  overflow-y: auto;
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  text-align: left;
  z-index: 998;
  opacity: 0;
  visibility: hidden;

  #search-results {
    padding: 16px 12px 12px;
    border: 1px solid $gray-1;
    border-radius: 0 0 3px 3px;
    color: $body-color;
    background: $white;
  }

  p {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.5;
  }

  .search-quick-result {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: $black;
  }

  .search_list {
    position: relative;
    margin: 11px -10px -20px;
    transform: translateY(-20px);

    > li {
      display: inline-block;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      width: 32.5%;

      @include media-breakpoint-up(xl) {
        width: 24.6%;
      }
    }

    > li.search_all {
      width: 100%;
    }

    > li:only-child {
      width: 100%;
    }
  }

  .search_link {
    color: $black;

    &:hover {
      color: $primary;
    }
  }

  .search_error {
    font-size: 12px;
    line-height: 1.5;
  }

  .search-title {
    font-size: 15px;
  }

  .search_submit {
    display: block;
    padding: 5px 25px;
    border-radius: 3px;
    border: 1px solid $gray-light;
    font-size: 13px;
    font-weight: $btn-font-weight;
    color: $black;
    letter-spacing: .06em;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

  .match {
    display: none;
  }

  .not-empty ~ & {
    visibility: visible;
    opacity: 1;
  }
}

// RD Search results
//
.rd-search-results {
  .search_list {
    counter-reset: li;
    text-align: left;
    padding-left: 0;
    font-size: 18px;
    list-style-type: none;
    overflow: hidden;

    li div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
    }

    li:only-child::before {
      display: none;
    }
  }

  .search-list-item {
    position: relative;
    padding-left: 40px;
    font-size: $font-size-base;
    color: $gray-darker;
    &:before {
      position: absolute;
      left: 0;
      top: -2px;
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      color: $gray;
    }
  }

  .search-list-item + .search-list-item {
    margin-top: 40px;
  }

  .search-title {
    font-size: 18px;
    color: $black;
    a:hover {
      color: $primary;
    }
  }

  .search {
    color: $white;
    padding: 0 .25em;
    background: $primary;
  }

  .match {
    font: 400 14px $font-family-base;
    color: $gray;
  }


  * + .match {
    margin-top: 2px;
  }

  * + p {
    margin-top: 16px;
  }

  @include media-breakpoint-up(md) {
    .search-list-item {
      padding-left: 50px;
    }

    .search-list-item + .search-list-item {
      margin-top: 50px;
    }
  }
}

* + .rd-search-results {
  margin-top: 40px;
}

@include media-breakpoint-up(md) {
  * + .rd-search-results {
    margin-top: 55px;
  }
}

// Form search classic
//
.search-form-classic {
  position: relative;

  .form-input {
    padding-right: 40px;
  }

  .form-input,
  .form-label {
    letter-spacing: .05em;
  }

  .rd-search-submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(center);
    width: 40px;
    padding-right: 2px;
    text-align: center;
    color: $secondary;

    &:before {
      position: relative;
      top: -1px;
      content: '\e09c';
      font: 400 24px 'fl-bigmug-line';
      line-height: 1;
      transition: .33s all ease;

    }

    &:hover {
      color: $gray-darker;
    }
  }
}

* + .search-form-classic {
  margin-top: 25px;
}