//
// Links
// --------------------------------------------------
.link-image {
  @include responsive-block;
  img {
    height: auto;
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .desktop {
    .link-image {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      transition: .44s all ease;
      border-style: solid;
      border-width: 2px;
      border-color: #f1f1f1;


      &:hover {
        box-shadow: $shadow-area-lg;
        border-radius: 15px;
        border-color: #FF8826;
        background-color: #f9f9f9;

      }
    }
  }
}

.link-gray-4 {
  @include link($gray-4, $black);
}

// Link transparent
.link-transparent {
  padding: 1px 20px;
  text-align: center;
}

//Hide Link Text
.hidden-text {
 font-size: small;
 padding-bottom:0px;
 white-space: nowrap;


}