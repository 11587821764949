//
// Thumb
// --------------------------------------------------
.thumb-default img {
  width: 100%;
  height: auto; }

.thumb-default figcaption {
  color: #888; }

.thumb-default * + figcaption {
  margin-top: 10px; }

figure img {
  display: block; }

.figure-inline img {
  width: auto; }

.figure-centered {
  text-align: center; }
  .figure-centered img {
    display: inline-block;
    width: auto; }

.thumb {
  text-align: center; }

.thumb__title {
  font-size: 20px;
  line-height: 1.33;
  color: #000; }
  .thumb__title a {
    color: inherit; }
    .thumb__title a:hover {
      color: #fa225b; }

.thumb__subtitle {
  font-size: 14px;
  line-height: 1.71429;
  font-style: italic; }

* + .thumb__subtitle {
  margin-top: 5px; }

@media (min-width: 1200px) {
  .thumb__title {
    font-size: 24px;
    line-height: 1.3; } }

.thumb-minimal {
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  width: 100%;
  padding-bottom: 100%;
  border-radius: 3px; }
  .thumb-minimal a {
    display: block; }
  .thumb-minimal figure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .thumb-minimal img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: auto;
    will-change: transform;
    border-radius: 3px; }
  .thumb-minimal:hover .thumb-minimal__overlay {
    opacity: 1; }
    .thumb-minimal:hover .thumb-minimal__overlay::before {
      transform: scale(1); }

.thumb-minimal__overlay {
  position: absolute;
  top: -1px;
  right: 0;
  bottom: 0;
  left: -1px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(255, 136, 38, 0.9);
  border-radius: 3px;
  opacity: 0;
  transition: all .4s ease-out;
  will-change: opacity, transform; }
  .ipad .thumb-minimal__overlay {
    bottom: -1px; }
  .thumb-minimal__overlay::before {
    position: relative;
    display: block;
    content: '\e936';
    font-family: 'Linearicons';
    font-size: 24px;
    color: #fff;
    transform: scale(0);
    transition: all .4s ease-out; }

.thumb-minimal.thumb-minimal_lg .thumb-minimal__overlay::before {
  font-size: 50px; }

@media (max-width: 767px) {
  .thumb-corporate {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto; } }

.thumb-corporate img {
  width: 175px;
  height: 220px;
  object-fit: cover; }

.thumb-corporate * + .thumb-corporate__list {
  margin-top: 15px; }

.thumb-corporate__overlay {
  padding: 20px 10px 5px; }

* + .thumb-corporate__caption {
  margin-top: 10px; }

@media (min-width: 992px) {
  .desktop .thumb-corporate:hover .thumb-corporate__overlay {
    opacity: 1; }
    .desktop .thumb-corporate:hover .thumb-corporate__overlay .thumb-corporate__list > li {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
      transition-delay: .1s; }
  .desktop .thumb-corporate__main {
    position: relative; }
  .desktop .thumb-corporate__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -2px;
    left: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    pointer-events: none; }
    .desktop .thumb-corporate__overlay::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(51, 51, 51, 0.8);
      transition: transform .3s ease-in-out; }
    .desktop .thumb-corporate__overlay .thumb-corporate__list {
      pointer-events: auto; }
      .desktop .thumb-corporate__overlay .thumb-corporate__list > li {
        position: relative;
        opacity: 0;
        transition: transform .3s ease-in-out, opacity .1s;
        transform: matrix(0.01, 0, 0, 1, 0, 0); } }

@media (min-width: 1200px) {
  * + .thumb-corporate__caption {
    margin-top: 25px; } }

.thumb-classic {
  position: relative;
  overflow: hidden;
  cursor: default; }
  @media (max-width: 767px) {
    .thumb-classic {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }
  .thumb-classic * + [class*=list-inline-] {
    margin-top: 26px; }

* + .thumb-classic__caption {
  margin-top: 20px; }

@media (min-width: 992px) {
  .desktop .thumb-classic:hover .thumb-classic__caption {
    opacity: 2;
    transform: matrix(1, 0, 0, 1, 0, 0); }
  .desktop .thumb-classic__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; }
    .desktop .thumb-classic__image img {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -10%);
      width: auto;
      height: auto;
      min-height: 100%;
      min-width: 100%;
      max-width: none; }
  .desktop .thumb-classic__caption {
    position: relative;
    z-index: 2;
    padding: 45px 20px;
    margin: 0;
    opacity: 0;
    transform: matrix(1.1, 0, 0, 1.15, 0, 20);
    background: rgba(255, 136, 38, 0.9);
    color: #fff;
    transition: .33s all ease; }
    .desktop .thumb-classic__caption a {
      color: #fff; }
      .desktop .thumb-classic__caption a:hover {
        color: #000; } }


@media (min-width: 1200px) {
  .thumb-classic__caption {
    padding: 35px 15px 20px; } }

@media (min-width: 1600px) {
  .thumb-classic__caption {
    padding: 60px 30px; } }

.thumb-modern {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  margin-top: -1px;
  padding-bottom: 75.21%;
  transform: translate3d(0, 0, 0); }
  @media (max-width: 767px) {
    .thumb-modern {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }
  .thumb-modern img {
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    max-width: none; }
  .thumb-modern:hover .thumb-modern__overlay {
    opacity: 1; }
    .thumb-modern:hover .thumb-modern__overlay::before {
      transform: scale(1); }

.thumb-modern__overlay {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(255, 136, 38, 0.9);
  opacity: 0;
  transition: all .4s ease-out;
  will-change: opacity, transform; }
  .thumb-modern__overlay::before {
    position: relative;
    display: block;
    content: '\e936';
    font-family: 'Linearicons';
    font-size: 30px;
    color: #fff;
    transform: scale(0);
    will-change: transform;
    transition: all .4s ease-out; }
  @media (min-width: 768px) {
    .thumb-modern__overlay::before {
      font-size: 50px; } }

.thumb-centered {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  text-align: center;
  max-height: 720px; }
  @media (max-width: 767px) {
    .thumb-centered {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }
  .thumb-centered img {
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
    transform: translate3d(-50%, -50%, 0);
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    max-width: none; }

.firefox .thumb-centered {
  display: table;
  border: 0;
  width: calc(100% - 8px);
  max-width: 100%;
  margin-top: 3px;
  margin-bottom: 3px; }

@media (min-width: 992px) {
  .thumb-centered.thumb-centered-md__rect {
    padding-bottom: 50%; } }

.thumb-centered__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
  -webkit-filter: blur(0);
  transform: translate3d(-50%, -50%, 0);
  min-width: 101%; }
  .thumb-centered__caption, .thumb-centered__caption h1, .thumb-centered__caption h2, .thumb-centered__caption h3, .thumb-centered__caption h4, .thumb-centered__caption h5, .thumb-centered__caption h6, .thumb-centered__caption .heading-1, .thumb-centered__caption .heading-2, .thumb-centered__caption .heading-3, .thumb-centered__caption .heading-4, .thumb-centered__caption .heading-5, .thumb-centered__caption .heading-6 {
    color: #fff; }

@media (min-width: 992px) and (max-width: 1599px) {
  .thumb-centered_responsive {
    padding: 0; }
    .thumb-centered_responsive img {
      width: 101%;
      position: relative;
      left: 0;
      top: 0;
      transform: none; } }

.thumbnail {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: left; }
  .thumbnail img {
    transition: 370ms ease-in-out; }
  .thumbnail-classic .caption {
    margin-top: 25px; }

a.thumbnail-title:hover {
  color: #fa225b; }  

.thumbnail-classic {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: left; }
  .thumbnail-classic img {
    transition: 370ms ease-in-out;
    border-radius: 15px; }
  .thumbnail-classic .caption {
    margin-top: 25px; }
  .thumbnail-classic:hover img {
    box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.15); }

a.thumbnail-classic-title:hover {
  color: #fa225b; }

.thumbnail-classic-time {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  color: #fa225b; }
  * + .thumbnail-classic-time {
    margin-top: 15px; }

.thumbnail-classic-minimal {
  text-align: center; }
  .thumbnail-classic-minimal img {
    width: auto; }
  .thumbnail-classic-minimal .caption {
    margin-top: 28px;
    text-align: left;
    font-size: 14px;
    line-height: 24px; }

.img-thumbnail-variant-1 {
  position: relative; }
  .img-thumbnail-variant-1 .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 136, 38, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(0.8);
    transition: 350ms ease-in-out;
    color: #fff; }
  .img-thumbnail-variant-1:hover .caption {
    opacity: 1;
    transform: none; }

.img-thumbnail-variant-2 {
  position: relative;
  overflow: hidden; }
  .img-thumbnail-variant-2 .caption {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0;
    transform: scale(0.8) translateX(100%);
    transition: 350ms ease-in-out; }
  .img-thumbnail-variant-2 .icon-lg {
    font-size: 30px; }
  .img-thumbnail-variant-2:hover .caption {
    opacity: 1;
    transform: scale(1) translateX(0); }

.img-thumbnail-variant-3 {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-color: #FF8A27; 
  border-width: .5px;
  border-style: solid;
  border-radius: 25px; }
  .img-thumbnail-variant-3 .icon {
    font-size: 10px; }
  .img-thumbnail-variant-3 .arrow-right {
    font-size: 10px; }
  .img-thumbnail-variant-3 .list-inline-tag {
    font-size: 12px;
    font-style: italic; }
  .img-thumbnail-variant-3 .label-custom {
    position: absolute;
    z-index: 11;
    top: 18px;
    left: 18px;
    padding: 2px 7px;
    font-size: 12px;
    font-style: italic;
    background-color: #fff;
    border-radius: 4px;
    color: #000; }
  .img-thumbnail-variant-3 .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-color: rgba(255, 136, 38, 0.9);
    transition: 150ms ease-in-out; }
    .img-thumbnail-variant-3 .caption > * {
      color: #fff; }
    .img-thumbnail-variant-3 .caption > * + * {
      margin-top: 5px; }
    .img-thumbnail-variant-3 .caption .divider {
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 116px;
      margin-left: auto;
      margin-right: auto; }
  @media (min-width: 1200px) {
    .img-thumbnail-variant-3 .icon {
      font-size: 40px; }
    .img-thumbnail-variant-3 .caption {
      padding: 50px 55px 45px;
      font-size: 10px; }
      .img-thumbnail-variant-3 .caption > * + * {
        margin-top: 10px;
        font-size: 12px; }
      .img-thumbnail-variant-3 .caption .divider {
        margin-top: 19px;
        margin-bottom: 19px; }
    .img-thumbnail-variant-3 .arrow-right {
      position: absolute;
      right: 18px;
      bottom: 13px; } }
  .desktop .img-thumbnail-variant-3 .caption {
    opacity: 0;
    transform: scale(0.8); }
  .desktop .img-thumbnail-variant-3 .divider {
    background: transparent; }
    .desktop .img-thumbnail-variant-3 .divider:before {
      position: absolute;
      content: "";
      display: inline-block;
      left: 50%;
      right: 50%;
      height: 1px;
      background-color: #fff;
      transition: 160ms 190ms ease-in-out; }
  .desktop .img-thumbnail-variant-3 .hover-top-element {
    opacity: 0;
    transform: translateY(30px);
     }
  .desktop .img-thumbnail-variant-3 .hover-bottom-element {
    opacity: 0;
    transform: translateY(-40px);
    transition: 250ms 450ms linear; }
  .desktop .img-thumbnail-variant-3:hover .caption {
    opacity: 1;
    transform: scale(1); }
  .desktop .img-thumbnail-variant-3:hover .hover-top-element,
  .desktop .img-thumbnail-variant-3:hover .hover-bottom-element {
    opacity: 1;
    transform: translateY(0); }
  .desktop .img-thumbnail-variant-3:hover .divider:before {
    left: 0;
    right: 0; }

@media (max-width: 767px) {
  .image-sizing-1 {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto; } }

.quote-centered {
  position: relative;
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.quote-centered__text .q {
  font-size: 16px;
  color: #000; }

.quote-centered__mark {
  display: inline-block;
  fill: #fa225b; }

.quote-centered__cite {
  color: #888;
  vertical-align: middle; }
  .quote-centered__cite:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
    border-top: 1px solid; }

* + .quote-centered__cite {
  margin-top: 10px; }

@media (min-width: 768px) {
  .quote-centered__text .q {
    font-size: 24px;
    line-height: 1.43333; } }

@media (min-width: 1200px) {
  .quote-centered {
    max-width: 760px; }
  .quote-centered__text .q {
    font-size: 30px; } }

.quote-default {
  position: relative;
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  @media (max-width: 767px) {
    .quote-default {
      max-width: 370px;
      margin-left: auto;
      margin-right: auto; } }

@media (min-width: 1200px) {
  .quote-default {
    max-width: 865px; } }

.quote-default__mark {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  fill: #fa225b; }

.quote-default__image img {
  width: 100px;
  height: auto;
  border-radius: 100%; }

.quote-default__cite {
  vertical-align: middle;
  color: #000; }
  .quote-default__cite:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
    border-top: 1px solid; }

* + .quote-default__mark {
  margin-top: 30px; }

* + .quote-default__text {
  margin-top: 20px; }

* + .quote-default__cite {
  margin-top: 15px; }

.quote-default.quote-default_left {
  text-align: left; }
  .quote-default.quote-default_left .quote-default__mark {
    margin-left: 0; }

.quote-default.quote-default_left-v2 {
  text-align: left; }
  .quote-default.quote-default_left-v2 .quote-default__image {
    text-align: center; }
  .quote-default.quote-default_left-v2 .quote-default__mark {
    margin-left: 0; }

@media (min-width: 768px) {
  * + .quote-default__text {
    margin-top: 30px; } }

.quote-bordered {
  padding: 20px;
  border: 1px solid #ececee;
  border-radius: 3px;
  text-align: center; }

.quote-bordered__mark {
  display: block;
  fill: #fa225b; }

.quote-bordered__image img {
  width: 100px;
  height: auto;
  border-radius: 100%; }

.quote-bordered__cite {
  color: #000; }

.quote-bordered__small {
  font-size: 14px;
  line-height: 1.71429; }

* + .quote-bordered__image {
  margin-top: 15px; }

* + .quote-bordered__small {
  margin-top: 0; }

* + .quote-bordered__text {
  margin-top: 25px; }

* + .quote-bordered__caption {
  margin-top: 10px; }

@media (min-width: 1200px) {
  .quote-bordered {
    padding: 40px; }
  * + .quote-bordered__text {
    margin-top: 50px; }
  * + .quote-bordered__caption {
    margin-top: 30px; } }

.quote-classic__mark {
  fill: #fa225b; }

.quote-classic__main {
  position: relative;
  padding: 25px;
  margin-bottom: 12px;
  border: 1px solid #ececee;
  border-bottom: 0; }
  .quote-classic__main:before, .quote-classic__main:after {
    content: '';
    position: absolute;
    bottom: -12px;
    height: 13px;
    border-style: solid;
    border-color: #ececee;
    transform-origin: 0 0; }
  .quote-classic__main:before {
    left: 0;
    width: 40px;
    border-width: 1px 1px 0 0;
    transform: skew(45deg); }
  .quote-classic__main:after {
    right: 0;
    width: calc(100% - 40px - 25px);
    border-width: 1px 0 0 1px;
    transform: skew(-45deg); }

.quote-classic__image img {
  border-radius: 100%;
  width: 40px;
  height: auto; }

.quote-classic__caption {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.quote-classic__caption-main {
  padding-left: 20px; }

.quote-classic__cite {
  color: #000; }

.quote-classic__small {
  font-size: 14px;
  line-height: 1.71429; }

* + .quote-classic__text {
  margin-top: 8px; }

* + .quote-classic__small {
  margin-top: 0; }

.quote-classic__main + .quote-classic__caption {
  margin-top: 35px; }

@media (min-width: 576px) {
  .quote-classic__image img {
    width: 100px; } }

@media (min-width: 1200px) {
  .quote-classic__main {
    padding: 40px; }
  .quote-classic__caption-main {
    padding-left: 40px; } }
