/*
*
* Grid system
* --------------------------------------------------
*/

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  //max-width: 2560px;
    
  [class*='col'] {
    > img {
      width: 100%;
    }
  }
}

.container-flex {
  .row > [class*='col'] {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(wrap);
  }

  .col__inner {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(center);
    padding: 35px 25px;
    width: 100%;

    > * {
      max-width: 100%;
      width: 100%;
    }
  }

  .firefox & {
    .row > [class*='col'] {
      overflow: hidden;
    }
  }

  .rd-google-map {
    @include display-flex;
    @include align-self(stretch);
    @include flex-grow(1);
    @include media-breakpoint-down(sm) {
      min-height: 300px;
      height: auto;
    }

    @include media-breakpoint-up(md) {
      height: 0;
      padding-bottom: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    .col__inner {
      padding: 45px 35px;
    }
  }

  @include media-breakpoint-up(xxl) {
    .col__inner {
      padding: 152px 15.625%;
    }

    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xl-8 {
      .col__inner { 
        padding-left: 12.5%;
        padding-right: 12.5%;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .shell-fluid-inset-lg-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
}


.range-spacer {
  @include flex-basis(100%);
}


html.lt-ie-10 .range-condensed,
.range-condensed {
  margin-left: 0;
  margin-right: 0;

  > [class*='cell'] {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
}
