// default theme
.lg-actions {
    .lg-next, .lg-prev {
        background-color: $lg-next-prev-bg;
        border-radius: $lg-border-radius-base;
        color: $lg-next-prev-color;
        cursor: pointer;
        display: block;
        font-size: 22px;
        margin-top: -10px;
        padding: 8px 10px 9px;
        position: absolute;
        top: 50%;
        z-index: $zindex-controls;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &:hover {
            color: $lg-next-prev-hover-color;
        }
    }

    .lg-next {
        right: 20px;

        &:before {
            content: "\e095";
        }
    }

    .lg-prev {
        left: 20px;

        &:after {
            content: "\e094";
        }
    }
}

@include keyframes(lg-right-end) {
    0% {
        left: 0;
    }

    50% {
        left: -30px;
    }

    100% {
        left: 0;
    }
}


@include keyframes(lg-left-end) {
    0% {
        left: 0;
    }

    50% {
        left: 30px;
    }

    100% {
        left: 0;
    }
}


.lg-outer {
    &.lg-right-end {
        .lg-object {
            @include animation(lg-right-end 0.3s);
            position: relative;
        }
    }

    &.lg-left-end {
        .lg-object {
            @include animation(lg-left-end 0.3s);
            position: relative;
        }
    }
}

// lg toolbar
.lg-toolbar {
    display: flex;
    flex-direction: row-reverse;
    z-index: $zindex-toolbar;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $lg-toolbar-bg;

    .lg-icon {
        color: $lg-toolbar-icon-color;
        cursor: pointer;
        float: right;
        font-size: 24px;
        height: 47px;
        line-height: 27px;
        padding: 10px 0;
        text-align: center;
        width: 50px;
        text-decoration: none !important;
        outline: medium none;
        @include transition(color 0.2s linear);

        &:hover {
            color: $lg-toolbar-icon-hover-color;
        }
    }

    .lg-close {
        &:after {
            content: "\e070";
        }
    }

    .lg-download {
        &:after {
            content: "\e0f2";
        }
    }
}

// lightGallery title
.lg-sub-html {
    background-color: $lg-sub-html-bg;
    bottom: 0;
    color: $lg-sub-html-color;
    font-size: 16px;
    left: 0;
    padding: 10px 40px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: $zindex-subhtml;

    h4 {
        margin: 0;
        font-size: 13px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        margin: 5px 0 0;
    }
}

// lg image counter
#lg-counter {
    color: $lg-icon-color;
    display: inline-block;
    font-size: $lg-counter-font-size;
    padding-left: 20px;
    padding-top: 12px;
    vertical-align: middle;

    margin-right: auto;
}

// for idle state
.lg-toolbar, .lg-prev, .lg-next {
    opacity: 1;
    @include transitionCustom(transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear);
}

.lg-hide-items {
    .lg-prev {
        opacity: 0;
        @include translate3d(-10px, 0, 0);
    }

    .lg-next {
        opacity: 0;
        @include translate3d(10px, 0, 0);
    }

    .lg-toolbar {
        opacity: 0;
        @include translate3d(0, -10px, 0);
    }
}

// Starting effect
body:not(.lg-from-hash){
    .lg-outer {
        &.lg-start-zoom{
            .lg-object{
                @include scale3d(0.5, 0.5, 0.5);
                opacity: 0;
                @include transitionCustom(transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important);
                transform-origin: 50% 50%;
            }
            .lg-item.lg-complete{
                .lg-object{
                    @include scale3d(1, 1, 1);
                    opacity: 1;
                }
            }
        }
    }
}