// Element-groups
@mixin group($offset-x, $offset-y: $offset-x) {
  margin-bottom: -$offset-y;
  margin-left: -$offset-x;

  &:empty {
    margin-bottom: 0;
    margin-left: 0;
  }

  > * {
    display: inline-block;
    margin-top: 0;
    margin-bottom: $offset-y;
    margin-left: $offset-x;
  }
}

@mixin group-contextual($offset-x, $offset-y: $offset-x) {
  margin-bottom: -$offset-y;
  margin-left: -$offset-x;

  > * {
    margin-bottom: $offset-y;
    margin-left: $offset-x;
  }
}