//
// RD Video BG
// --------------------------------------------------

// General Styles
.rd-video {
  position: relative;
  z-index: 5;

  video {
    margin: auto;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    &.transition {
      transition: .3s all linear;
    }
  }
  // Video Wrapper
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }

  // Preloader
  &-preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease;
    background: url(../images/preloader.gif) no-repeat rgba(#fff, .8) center center;
    z-index: 6;
  }

  // When Loading
  &.loading {

    .rd-video-preloader {
      opacity: 1;
      visibility: visible;
    }
  }

  &-context-wrapper {
    z-index: 99999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: none;
    opacity: 0;
    visibility: hidden;
    text-align: left;

    &.show {
      background: rgba(#000, .7);
      opacity: 1;
      visibility: visible;
      .rd-video-context {
        bottom: 0;
      }
    }

    transition: .3s background ease;
    .rd-video-context {
      padding: 2px 0;
      background: $white;
      position: absolute;
      left: 0;
      bottom: -100%;
      right: 0;
      transition: .5s bottom ease;
    }

    h6 {
      padding: 5px 10px 5px 15px;
      text-transform: none;
    }
    ul {
      li {
        cursor: pointer;
        font-size: 14px;
        padding: 5px 5px 5px 15px;
        text-transform: none;
        border-top: 1px solid #e9e9e9;
        &:hover, &.active {
          background: $primary;
          color: $white;
        }
        &.active {
          cursor: default;
        }
      }
    }

  }

  // Context Menu
  .desktop &-context-wrapper {
    position: absolute;
    width: 160px;
    transform: none;
    background: $white;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    .rd-video-context {
      position: static;
    }
  }
}

// Custom Styles

.vide_bg {
  width: 100%;

  .container {
    position: relative;
    z-index: 2;
  }
}

.video-bg-overlay {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, .2);   
    z-index: 1;
  }
}