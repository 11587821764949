// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-default {
  background-color: $white !important;
}

.bg-light-gray {
	background-color: #F1F1F1;
}

.bg-dark-gray {
  background-color: #333333;
}

.bg-black-fade {
	background-color: black;
	background-image: linear-gradient(#000,#333);
}

.bg-gray-fade {
	background-color: #333;
	background-image: linear-gradient(#333,#555);
}

.bg-light-gray-fade {
	background-color: #555;
	background-image: linear-gradient(#555,#999);
}

.bg-zuar-pink-gradient {
  background: rgb(252,116,152);
    background: -webkit-linear-gradient(352deg, rgba(252,116,152,1) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(352deg, rgba(252,116,152,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(82deg, rgba(252,116,152,1) 0%, rgba(255,255,255,1) 100%);
}   

.bg-zuar-gradient {
	background: rgb(250,34,91);
    background: -webkit-linear-gradient(352deg, rgba(250,34,91,1) 0%, rgba(255,136,38,1) 100%);
    background: -o-linear-gradient(352deg, rgba(250,34,91,1) 0%, rgba(255,136,38,1) 100%);
    background: linear-gradient(82deg, rgba(250,34,91,1) 0%, rgba(255,136,38,1) 100%);
}				

.bg-transparent {
  background-color: transparent !important;
}

.bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.6) !important;
}
