.jumbotron {
  padding: $jumbotron-padding ($jumbotron-padding / 2);
  margin-bottom: $jumbotron-padding;
  background-color: $jumbotron-bg;
  @include border-radius($border-radius-lg);

  @include media-breakpoint-up(sm) {
    padding: ($jumbotron-padding * 2) $jumbotron-padding;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);
}


.z-top {
  position: relative;
  z-index:2;
}

  #video-container{
    position:relative;
    width:auto;
    background-color: #000;
  }
  #foreground-text{
    position:absolute;
    top:10%;width:100%;text-align:center;
    margin:0 auto;
    color: white;
    font: bold 20px;
  }
.jumbotron{
  position: relative;
  z-index:0;
}

#video-background { 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  max-width:100%;
  -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein .5s; /* Firefox < 16 */
  -ms-animation: fadein .5s; /* Internet Explorer */
  -o-animation: fadein .5s; /* Opera < 12.1 */
  animation: fadein .5s;
}

#image-background-center { 
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
  overflow: hidden;
  z-index: -100;
  max-width:100%;
  -webkit-filter: grayscale(1);
  filter:grayscale(1);
  -webkit-filter: opacity(.2);
  filter: brightness(.4);
}

#image-background-left { 
  position: absolute;
  top: -65%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  max-width:100%;
  -webkit-filter: brightness(.9);
  filter: brightness(.9);
}

#image-background-right { 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -100;
  max-width:100%;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-filter: opacity(.2);
  filter: brightness(.4);
    
}

#image-tint {
  -webkit-filter: brightness(.4);
  filter: brightness(.4);
}

#counter-tint {
  -webkit-filter: brightness(1) !important;
  filter: brightness(1) !important;
  color: white;
}

@keyframes fadein {
    from { opacity: .5; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: .5; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: .5; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: .5; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: .5; }
    to   { opacity: 1; }
}