// Contexts
// -------------------------

%context-dark {
  color: $white;

  #{headings()} {
    color: $white;
  }

  a {
    @include link(inherit, $primary);
  }

  .button-link {
    &:hover {
      color: $white;
    }
  }

  // Blurb
  .blurb__title {
    color: $white;
  }

  .blurb-circle {
    &,
    .blurb-circle__icon .icon {
      color: $white;
    }
  }

  .blurb-minimal {
    color: $body-color;
  }

  // Countdown
  .countdown-default {
    .countdown-section {
      border-color: $white;
    }

    .countdown-period,
    .countdown-amount {
      color: $white;
    }
  }

  // Counters
  .box-counter {
    color: $white;
  }

  .box-counter__icon {
    color: $white;
  }

  // Progress bars
  .progress-linear,
  .progress-bar-circle-title,
  .progress-bar-circle span {
    color: $white;
  }

  // Thumbnails
  .thumb__title {
    color: $white;

    a {
      &:hover {
        color: $primary;
      }
    }
  }

  .button-gray-light-outline {
    @include btn-variant($white, transparent, $white, $white, $primary, $primary);
  }
}

%context-light {
  #{headings()} {
    color: $headings-color;
  }

  @include media-breakpoint-down(lg) {
    .parallax-header {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($gray-3, .33);
      }
    }
  }
}

.context-dark {
  @extend %context-dark;
}

.context-light {
  @extend %context-light;
}

// Background colors
//
.bg-default {
  @include bg-behaviour($white);
}

.bg-accent,
.bg-secondary-7 {
  @extend %context-dark;
  .button-primary {
    @include btn-variant($black, $white, $white, $white, $black, $black);
  }

  .button-black {
    @include btn-variant($white, $black, $black, $black, $white, $white);
  }

  .button-gray-light-outline {
    @include btn-variant($white, transparent, $white, $black, $white, $white);
  }

  // Progress bars
  .progress-linear-modern {
    .progress-bar-linear-wrap {
      &:after {
        background: $white;
      }
    }
  }

  // Blurb
  .blurb-minimal {
    &,
    .blurb-minimal__icon .icon {
      color: $white;
    }
  }
}

.bg-accent {
  @include bg-behaviour($primary);
}

.bg-accent-2 {
  background: rgb(250,34,91);
  background: linear-gradient(172deg, rgba(250,34,91,1) 0%, rgba(247,140,41,1) 100%);
}

.bg-accent-gradient {
  background-image: linear-gradient(to right, $primary 15%, darken($primary, 15%));
}
.bg-accent-gradient-black {
  background-image: linear-gradient(to right, $gray-dark 15%, darken($black, 15%));
}
.bg-accent-gradient-black-2 {
  background-image: linear-gradient(to top, $gray-dark 15%, darken($black, 15%));
}
.bg-secondary-7 {
  @include bg-behaviour($secondary-7);
}

.bg-secondary-8 {
  @include bg-behaviour($secondary-8);
}

.bg-gray-dark {
  @extend %context-dark;
  @include bg-behaviour($gray-dark);
}
.bg-black {
  @extend %context-dark;
  @include bg-behaviour($black);
}

.bg-gray-darker {
  @extend %context-dark;
  @include bg-behaviour($gray-dark);
}

.bg-black {
  @include bg-behaviour($black);

  .blurb__title {
    color: $white;
  }
}

.bg-gray-light {
  @extend %context-light;
  @include bg-behaviour($gray-light);
}

.bg-gray-lighter {
  @extend %context-light;
  @include bg-behaviour($gray-lighter);
}

// Background image
.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-bottom {
  background-position: center bottom;
}

.bg-image-runner {
  background-image: url("/img/runner/runner-background.svg");
  background-size: contain;
  background-position: right 70px center;
  background-repeat: no-repeat;
  @extend %context-dark;
}

.bg-image-mitto {
  background-image: url("/img/mitto/mitto-background.webp"), url("/img/mitto/mitto-background.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @extend %context-dark;
}

.bg-image-mitto-2 {
  background-image: url("/img/mitto/mitto-web-banner.webp"), url("/img/mitto/mitto-web-banner.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  @extend %context-dark;
}

.bg-image-2 {
  background-image: url("/img/success-stories/office-hours.webp"), url("/img/success-stories/office-hours.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-team {
  background-image: url("/img/team/tc-min.jpg");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-image-team-2 {
  background-image: url("/img/team/tc-team-min.jpg");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bg-image-team-3 {
  background-image: url("/img/team/tc18team.jpg");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-image-sustainability {
  background-image: url("/img/sustainability-background.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-landing {
  background-image: url("/img/mitto/mitto-pattern.webp"), url("/img/mitto/mitto-pattern.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @extend %context-dark;
  @include bg-behaviour($gray-dark);
}

.bg-image-4 {
  @extend %context-dark;
  background-image: url("/img/webinar/data-strategy-webinar2.webp"), url("/img/webinar/data-strategy-webinar2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-blog {
  @extend %context-dark;
  background-image: url("/img/blog/space-bg.webp"), url("/img/blog/space-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-plant {
  @extend %context-dark;
  background-image: url("/img/success-stories/cannabis3-bg.webp"), url("/img/success-stories/cannabis3-bg.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-plant1 {
  @extend %context-dark;
  background-image: url("/img/success-stories/cannabis-bg.webp"), url("/img/success-stories/cannabis-bg.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-gaming {
  @extend %context-dark;
  background-image: url("/img/mitto/gaming_header_image-min.webp"), url("/img/mitto/gaming_header_image-min.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-service-overview {
  @extend %context-dark;
  /* background-image: url("/img/services/service-overview-img-min.jpeg"), url("/img/services/service-overview-img-min.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/services/service-overview-img-min.jpeg") no-repeat center/cover; 
}

.bg-image-data-strategy {
  @extend %context-dark;
  /* background-image: url("/img/services/zuar-labs/bg-image-zuar-labs.png"), url("/img/services/zuar-labs/bg-image-zuar-labs.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/services/zuar-labs/bg-image-zuar-labs.png") no-repeat top/cover; 
}

.bg-industrial {
  @extend %context-dark;
  /* background-image: url("/img/industrial-portal/industrial-background.png"), url("/img/industrial-portal/industrial-background.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/industrial-portal/industrial-background.png") no-repeat top/cover; 
}

.bg-media {
  @extend %context-dark;
  /* background-image: url("/img/industries/media/media-background.png"), url("/img/industries/media/media-background.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/industries/media/media-background.png") no-repeat top/cover; 
}

.bg-industries {
  @extend %context-dark;
  /* background-image: url("/img/industries-background.png"), url("/img/industries-background.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/industries-background.png") no-repeat top/cover; 
}

.bg-innovive {
  @extend %context-dark;
  /* background-image: url("/img/success-stories/innovive-bg.png"), url("/img/success-stories/innovive-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/success-stories/innovive-bg.png") no-repeat top/cover; 
}

.bg-duro {
  @extend %context-dark;
  /* background-image: url("/img/success-stories/duro-page-bg.png"), url("/img/success-stories/duro-page-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/success-stories/duro-page-bg.png") no-repeat top/cover; 
}

.bg-services {
  @extend %context-dark;
  /* background-image: url("/img/services-portal/services-background.png"), url("/img/services-portal/services-background.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/services-portal/services-background.png") no-repeat top/cover; 
}

.bg-image-zp-demo {
  @extend %context-dark;
  /* background-image: url("/img/portal-demo/zp-demo-bg.png"), url("/img/portal-demo/zp-demo-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/portal-demo/zp-demo-bg.png") no-repeat top/cover; 
}

.bg-image-zuar-labs {
  @extend %context-dark;
  /* background-image: url("/img/services/zuar-labs/bg-image-zuar-labs.png"), url("/img/services/zuar-labs/bg-image-zuar-labs.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
  background: linear-gradient(
      rgba(0, 0, 0, 0.65), 
      rgba(0, 0, 0, 0.65)
    ), url("/img/services/zuar-labs/bg-image-zuar-labs.png") no-repeat top/cover; 
}

.bg-fixed {
  .desktop & {
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
  }
}

* {
  margin: 0;
  padding: 0;
}
.imgbox {
  display: grid;
  height: 100%;
}
.center-fit {
   max-width: 100%;
   max-height: 100vh;
   margin: auto;
}