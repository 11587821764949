/*
*
* Text styling
* --------------------------------------------------
*/

// Text style
//

.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

// Text decoration
//

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

// Text weight
//

.text-thin {
  font-weight: 100;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-sbold {
  font-weight: 600;
}

.text-bold, strong {
  font-weight: 700;
}

.text-ubold {
  font-weight: 900;
}

.text-color-white {
  color: white !important;
}

.text-color-black {
  color: black !important;
}

.text-color-zuar {
  color: #fa225b !important;
}

.text-color-mitto {
  color: #ff8826 !important;
}

// Text spacing
//

.text-spacing-0 {
  letter-spacing: 0;
}

html {
  .text-accent {
    color: $primary;
  }

  .text-secondary {
    color: $secondary;
  }
}


// Text sizing
//

.text-0vw {
  font-size: .5vw;
}

.text-1vw {
  font-size: 1vw;
}

.text-15vw {
  font-size: 1.5vw;
}

.text-2vw {
  font-size: 2vw;
}

.text-25vw {
  font-size: 2.5vw;
}

.text-3vw {
  font-size: 3vw;
}

.text-35vw {
  font-size: 3.5vw;
}

//color changing animation
.awesome a {
  color:#313131;
  -webkit-animation:colorchange 3s infinite alternate;
}

@-webkit-keyframes colorchange {
  0% {
    color: #fff;
  }
  50% {
    
    color: #fa225b;
  }
  100% {
    
    color: #FB8928;
  }
}

//gradient text

.text-gradient {
  background-image: linear-gradient(to bottom right, #fa225b, #ff8826);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}