//
// Page loaders
// --------------------------------------------------

#page-loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  @include display-flex();
  @include flex-direction(column);
  @include justify-content(center);
  @include align-items(center);
  padding: 10px;
  z-index: 9999999;
  background: $white;
  transition: .3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {
    display: none;
  }
}

[data-x-mode="design-mode"] {
  #page-loader {
    display: none;
  }
}

.page-loader-image {
  display: block;
  text-align: center;
}

.page-loader-body {
  margin-top: 90px;
  text-align: center;
}

.cssload-container {
  width: 100%;
  height: 36px;
  text-align: center;
}

.cssload-speeding-wheel {
  width: 36px;
  height: 36px;
  margin: 0 auto;
  border: 3px solid $primary;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  animation: cssload-spin .88s infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}